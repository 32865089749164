import { v4 as uuidv4 } from "uuid";

import { CART } from "../variables";

const initialState = {
	items: [],
	totalQuantity: 0
};

export default function cartReducer(state = initialState, action) {
	const cartItem = state.items.find((item) => item.cartId === action.cartId);
	const cartItemIndex = cartItem && state.items.indexOf(cartItem);
	switch (action.type) {
		case CART.ADD_TO_CART:
			const addedCartItem = state.items.find(
				(item) => item.id === action.product.id
			);
			const addedCartItemIndex =
				addedCartItem && state.items.indexOf(addedCartItem);
			if (!addedCartItem) {
				return {
					...state,
					totalQuantity: state.totalQuantity + 1,
					items: [
						...state.items,
						{
							...action.product,
							qty: action.qty || 1,
							cartId: uuidv4(),
						},
					]
				};
			} else {
				return {
					...state,
					totalQuantity: state.totalQuantity + 1,
					items: [
						...state.items.slice(0, addedCartItemIndex),
						{
							...addedCartItem,
							qty: action.qty
								? addedCartItem.qty + action.qty
								: addedCartItem.qty + 1,
						},
						...state.items.slice(addedCartItemIndex + 1),
					]
				};
			}

		case CART.REMOVE_FROM_CART:
			return {
				...state, totalQuantity: state.totalQuantity - cartItem.qty, items: [
					...state.items.slice(0, cartItemIndex),
					...state.items.slice(cartItemIndex + 1),
				]
			};
		case CART.REMOVE_ALL_FROM_CART:
			return initialState;
		case CART.INCREASE_QUANTITY_CART:
			return {
				...state, totalQuantity: state.totalQuantity + 1, items: [
					...state.items.slice(0, cartItemIndex),
					{ ...cartItem, qty: cartItem.qty + 1 },
					...state.items.slice(cartItemIndex + 1),
				]
			};
		case CART.DECREASE_QUANTITY_CART:
			if (cartItem.qty < 2) {
				return { ...state };
			}
			return {
				...state,
				totalQuantity: state.totalQuantity - 1,
				items: [
					...state.items.slice(0, cartItemIndex),
					{ ...cartItem, qty: cartItem.qty - 1 },
					...state.items.slice(cartItemIndex + 1),
				]
			};
		default:
			return state;
	}
}
