import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers/rootReducer";

const cartPersistConfig = {
	storage,
	key: "queijos-del-este",
	// blacklist: ["cart"],
};

const defaultReducer = persistReducer(cartPersistConfig, rootReducer);
export const store = createStore(defaultReducer, {});
export const persistor = persistStore(store);