import React, { Suspense, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import styled from "styled-components";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { persistor, store } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import ReactPixel from "react-facebook-pixel";

const options = {
	autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
	debug: false, // enable logs
};
ReactPixel.init("567610098190653", {}, options);
ReactPixel.pageView(); // For tracking page view

const initStore = store;

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Pages
const Homefour = React.lazy(() => import("./components/pages/Homefour"));
const Events = React.lazy(() => import("./components/pages/Events"));
const About = React.lazy(() => import("./components/pages/About"));
const Login = React.lazy(() => import("./components/pages/Login"));
const Register = React.lazy(() => import("./components/pages/Register"));
const Checkout = React.lazy(() => import("./components/pages/Checkout"));
const Cart = React.lazy(() => import("./components/pages/Cart"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Menutwo = React.lazy(() => import("./components/pages/Menutwo"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Profile = React.lazy(() => import("./components/pages/Profile"));
const NotFoundPage = React.lazy(() => import("./components/pages/NotFoundPage"));
const ThankYouOrder = React.lazy(() => import("./components/pages/ThankYouOrder"));

const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
})

const WhatsappContainer = styled.div`
	position: fixed;
	z-index: 999;
	bottom: 10px;
	right: -38px;
	background: rgb(79 206 93);
	border-radius: 20px;
	height: 95px;
	width: 130px;
	display: flex;
	justify-content: center;
	align-items: center;
	> div ._1bpcM {
		margin-bottom: 0px !important;
		@media (max-width: 500px) {
			margin-right: 20px !important;
		}
	}
`;

function App() {
  return (
    <Provider store={initStore}>
      <PersistGate persistor={persistor}>
        <Router>
          <Suspense fallback={<div></div>}>
            <ScrollToTop>
              {/* <Preloader /> */}
              <Switch>
                <Route exact path="/" component={Homefour} />
                <Route path="/nosotros" component={About} />
                <Route path="/eventos" component={Events} />
                <Route path="/login" component={Login} />
                <Route path="/registro" component={Register} />
                <Route path="/checkout" component={Checkout} />
                <Route path="/carrito" component={Cart} />
                <Route path="/perfil" component={Profile} />
                <Route path="/error" component={Error} />
                <Route path="/tienda" component={Menutwo} />
                <Route path="/contacto" component={Contact} />
                <Route path="/contacto" component={Contact} />
                <Route path="/gracias/:orderId" component={ThankYouOrder} />
                <Route path="*" component={NotFoundPage} />
              </Switch>
              <WhatsappContainer>
                <WhatsAppWidget
                  phoneNumber="59891910928"
                  companyName={"Queijos del Este 🧀"}
                  textReplyTime={"Te responderemos en menos de 24hrs."}
                  message={"Hola! 👋🏼 \n\nCómo podemos ayudarte?"}
                  sendButton={"Enviar"}
                />
              </WhatsappContainer>
              <ToastContainer />
            </ScrollToTop>
          </Suspense>
        </Router >
      </PersistGate>
    </Provider>
  );
}

export default App;
