import { combineReducers } from "redux";

import cartReducer from "./cartReducer";
import deliveryReducer from "./deliveryReducer";
import authReducer from "./authReducer";
import datesReducer from "./datesReducer";

const rootReducer = combineReducers({
  cartBag: cartReducer,
  delivery: deliveryReducer,
  auth: authReducer,
  dates: datesReducer,
});

export default rootReducer;
