import { DATES } from "../variables";

const initialState = {
    lastDate: '',
};

export default function datesReducer(state = initialState, action) {
    switch (action.type) {
        case DATES.REFRESH_DATE:
            return {
                lastDate: new Date(),
            };
        case DATES.RESET_DATE:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}
