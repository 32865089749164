export const CART = {
	ADD_TO_CART: "ADD_TO_CART",
	REMOVE_FROM_CART: "REMOVE_FROM_CART",
	REMOVE_ALL_FROM_CART: "REMOVE_ALL_FROM_CART",
	DECREASE_QUANTITY_CART: "DECREASE_QUANTITY_CART",
	INCREASE_QUANTITY_CART: "INCREASE_QUANTITY_CART",
};

export const DELIVERY = {
	ADD_DELIVERY: "ADD_DELIVERY",
	REMOVE_DELIVERY: "REMOVE_DELIVERY",
};

export const USER = {
	SIGN_IN: 'SIGN_IN',
	SIGN_OUT: 'SIGN_OUT',
	UPDATE_INFO: 'UPDATE_INFO'
}

export const DATES = {
	REFRESH_DATE: 'REFRESH_DATE',
	RESET_DATE: 'RESET_DATE'
}