import { USER } from "../variables";

const initialState = {
    user: null,
    isLoggedIn: false,
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case USER.SIGN_IN:
            return {
                isLoggedIn: true,
                user: { ...action.user },
            };
        case USER.SIGN_OUT:
            return {
                ...initialState,
            };
        case USER.UPDATE_INFO:
            return {
                ...state,
                user: { ...state.user, ...action.user },
            };
        default:
            return state;
    }
}
